<template>
  <div class="page" style="text-align: left; " id="pageDiv" >
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox==false">Draco Natural Products, Inc. </b>
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{printModel.form.BaseInfoEditPrintTitle}}</b>
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>Vital Treasures Bioactive Botanical Extract Specification</u></b>
            </p>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.form.BaseInfoProductName">
            <td style="width: 25%"><b>产品名称</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.form.BaseInfoProductName }}
            </td>
          </tr>
          <tr v-show="printModel.form.versionNumber">
            <td style="width: 25%"><b>版本号</b></td>
            <td style="width: 75%">
              {{ printModel.form.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.form.BaseInfoItemCode">
            <td style="width: 25%"><b>产品编号</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.form.BaseInfoItemCode }}
            </td>
          </tr>
          <tr v-show="printModel.form.BaseInfoProductDescription">
            <td style="width: 25%"><b>产品说明</b></td>
            <td style="width: 75%">
              <span t-field="o.description" />{{
                printModel.form.BaseInfoProductDescription
              }}
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <div style="height: 10px"></div>
        <div style="font-size: 18px">
          <b>标准规格</b>
        </div>
        <!-- 遍历table -->
        <template v-if="printModel.inclNameArr.length > 0">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0"> 通用名/其他名称 </th>
                <th v-show="printModel.inclNameArr.length > 0">INCI名称</th>
                <th v-show="printModel.botanicalNameArr.length > 0"> 植物名称 </th>
                <th v-show="printModel.partUsedArr.length > 0">使用部位</th>
                <th v-show="printModel.percentageArr.length > 0"> 百分比 </th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.inclNameArr.length">
                  {{ printModel.inclNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style:italic;">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
                <td v-show=" i + 1 <= printModel.percentageArr.length && printModel.form.productType != 'Liquid EXT-S'">
                  {{ printModel.percentageArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <div v-else-if="isSingle().count == 1">
          <div style="width: 30%; float: left">
            <b>{{ isSingle().name }}</b>
          </div>
          <div style="width: 70%; float: left">
            <div
              style="width: 265px; float: left"
              v-for="(item, val, index) in isSingle().arr"
              :key="index"
            >
              {{ item }}
              <span v-if="val + 1 < isSingle().arr.length" style="">,</span>
            </div>
          </div>
        </div>
        <template v-else>
          <table
            style="width: 98%"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr>
                <th v-show="printModel.commonNameArr.length > 0">
                  通用名/其他名称
                </th>
                <th v-show="printModel.botanicalNameArr.length > 0">
                  植物名称
                </th>
                <th v-show="printModel.partUsedArr.length > 0">使用部分</th>
                <th
                  v-show="
                    printModel.percentageArr.length > 0
                  "
                >
                  百分比
                </th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.commonNameArr.length">{{
                    printModel.commonNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.botanicalNameArr.length > 0" style="font-style:italic;">
                  <div
                    v-show="i + 1 <= printModel.botanicalNameArr.length"
                    >{{ printModel.botanicalNameArr[i] }}</div
                  >
                </td>
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i + 1 <= printModel.partUsedArr.length">{{
                    printModel.partUsedArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.percentageArr.length > 0">
                  <div v-show="i + 1 <= printModel.percentageArr.length">{{
                    printModel.percentageArr[i]
                  }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.form.tableInput1" style="width:20%;">{{ printModel.form.tableInput1 }}</td>
                <td v-show="printModel.form.tableInput2" style="width:20%;">{{ printModel.form.tableInput2 }}</td>
                <td v-show="printModel.form.tableInput3" style="width:20%;">{{ printModel.form.tableInput3 }}</td>
                <td v-show="printModel.form.tableInput4" style="width:20%;">{{ printModel.form.tableInput4 }}</td>
                <td v-show="printModel.form.tableInput5" style="width:20%;">{{ printModel.form.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <!-- Analysis -->
        <table style="width: 99%">
          <tbody>
            <tr v-show="printModel.form.GeneralSpecNativeHerbs">
              <td style="width: 30%">
                <b>植物主要成分</b>
              </td>
              <td style="width: 35%">
                <span>{{
                  printModel.form.GeneralSpecNativeHerbs
                }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.appearance">
              <td style="width: 30%"><b>外观</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.appearance }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>
            <tr v-show="printModel.form.color">
              <td style="width: 30%"><b>颜色</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.color }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.ordor">
              <td style="width: 30%"><b>气味</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.ordor }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.taste">
              <td style="width: 30%"><b>味道</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.taste }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.solventForExtracti">
              <td style="width: 30%"><b>溶剂</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.solventForExtracti }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>
            <tr v-show="printModel.form.GeneralSpecPreservative">
              <td style="width: 30%"><b>防腐剂</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.GeneralSpecPreservative }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>
            <tr v-show="printModel.form.otherIngredients">
              <td style="width: 30%"><b>其他成分</b></td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{ printModel.form.otherIngredients }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.GeneralSpecCustomRowKey1">
              <td style="width: 30%">
                <b>{{ printModel.form.GeneralSpecCustomRowKey1 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{
                  printModel.form.GeneralSpecCustomRowValue1
                }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.GeneralSpecCustomRowKey2">
              <td style="width: 30%">
                <b>{{ printModel.form.GeneralSpecCustomRowKey2 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{
                  printModel.form.GeneralSpecCustomRowValue2
                }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>

            <tr v-show="printModel.form.GeneralSpecCustomRowKey3">
              <td style="width: 30%">
                <b>{{ printModel.form.GeneralSpecCustomRowKey3 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.apperance">{{
                  printModel.form.GeneralSpecCustomRowValue3
                }}</span>
              </td>
              <td style="width: 35%">
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr" :key="genIndex" >
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ item.customValue }}</span>
              </td>
            </tr>
          </tbody>

        <div style="height: 10px"></div>
          <tbody>
            <tr>
              <td style="width: 30%">
                <b><u>分析</u></b>
              </td>
              <td style="width: 35%">
                <b><u>产品规格</u></b>
              </td>
              <td style="width: 35%">
                <b><u>测试方法</u></b>
              </td>
            </tr>
            <tr v-show="printModel.form.analysisRangeBioactivesLevel">
              <td style="width: 30%"><b>活性成分</b></td>
              <td style="width: 35%">
                ≥{{ printModel.form.analysisRangeBioactivesLevel }}%
              </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodBioactivesLevel }}</td>
            </tr>
            <tr v-show="printModel.form.liquidExtCustomerKey1">
              <td style="width: 35%">
                <b>{{ printModel.form.liquidExtCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue1 }}
              </td> 
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue1TM }}
              </td>
            </tr>
            <tr v-show="printModel.form.liquidExtCustomerKey2">
              <td style="width: 35%">
                <b>{{ printModel.form.liquidExtCustomerKey2 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue2 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue2TM }}
              </td>
            </tr>
            <tr v-show="printModel.form.liquidExtCustomerKey3">
              <td style="width: 35%">
                <b>{{ printModel.form.liquidExtCustomerKey3 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue3 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue3TM }}
              </td>
            </tr>
            <tr v-show="printModel.form.liquidExtCustomerKey4">
              <td style="width: 35%">
                <b>{{ printModel.form.liquidExtCustomerKey4 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue4 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue4TM }}
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, genIndex) in printModel.analysisCustomFieldAttr" :key="genIndex" >
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{ item.customValue }}
              </td>
              <td style="width: 35%">
                {{ item.customValue1 }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr v-show="printModel.form.analysisRangePh">
              <td style="width: 30%">pH</td>
              <td style="width: 35%">
                {{ printModel.form.analysisRangePh }}
              </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodPh }}</td>
            </tr>
            <tr>
              <td style="width: 30%"><b>重金属</b></td>
              <td style="width: 35%">符合USP &lt;231&gt;</td>
              <td style="width: 35%">USP&lt;231&gt;</td>
            </tr>
            <tr v-if="printModel.form.specificationArsenic">
                <td style="width:30%"><b>砷</b></td>
                <td style="width:35%">&lt;{{printModel.form.specificationArsenic}} ppm</td>
                <td style="width:35%">{{printModel.form.testMethodArsenic}}</td>
            </tr>
            <tr v-if="printModel.form.specificationCadmium">
                <td style="width:30%"><b>镉</b></td>
                <td style="width:35%">&lt;{{printModel.form.specificationCadmium}} ppm</td>
                <td style="width:35%">{{printModel.form.testMethodCadmium}}</td>
            </tr>
            <tr v-if="printModel.form.specificationLead">
                <td style="width:30%"><b>铅</b></td>
                <td style="width:35%">&lt;{{printModel.form.specificationLead}} ppm</td>
                <td style="width:35%">{{printModel.form.testMethodLead}}</td>
            </tr>
            <tr v-if="printModel.form.specificationMercury">
                <td style="width:30%"><b>汞</b></td>
                <td style="width:35%">&lt;{{printModel.form.specificationMercury}} ppm</td>
                <td style="width:35%">{{printModel.form.testMethodMercury}}</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>农药残留</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">符合USP &lt;565&gt;</span>
              </td>
              <td style="width: 35%">USP&lt;565&gt;</td>
            </tr>
            <tr v-if="printModel.form.analysisRangeTotalPlateCount">
              <td style="width: 30%"><b>细菌总数</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.form.analysisRangeTotalPlateCount }} cfu/g
              </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodTotalPlateCount }}</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>酵母和霉菌</b></td>
              <td style="width: 35%">&lt;{{ printModel.form.analysisRangeYeastMold }} cfu/g</td>
              <td style="width: 35%">{{ printModel.form.analysisMethodYeastMold }}</td>
            </tr>
            <tr v-if="printModel.form.specificationEcoli">
              <td style="width: 30%"><b>大肠杆菌</b></td>
              <td style="width: 35%">
                {{ printModel.form.specificationEcoli
                }}<span
                  v-if="printModel.form.specificationEcoli != '阴性' && printModel.form.specificationEcoli.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodEColi }}</td>
            </tr>
            <tr v-if="printModel.form.specificationSalmonella">
              <td style="width: 30%"><b>沙门氏菌</b></td>
              <td style="width: 35%">
                {{ printModel.form.specificationSalmonella
                }}<span
                  v-if="printModel.form.specificationSalmonella != '阴性' && printModel.form.specificationSalmonella.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodSalmonella }}</td>
            </tr>
            <tr v-if="printModel.form.specificationStaphAureus">
              <td style="width: 30%"><b>金色葡萄球菌</b></td>
              <td style="width: 35%">
                {{ printModel.form.specificationStaphAureus
                }}<span
                  v-if="printModel.form.specificationStaphAureus != '阴性' && printModel.form.specificationStaphAureus.toLowerCase() != 'negative'"
                  > cfu/g</span
                >
              </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodStaphAureus }}</td>
            </tr>
            <tr v-if="printModel.form.testMethodTotalColiforms">
              <td style="width: 30%"><b>大肠菌群</b></td>
              <!-- <td style="width: 35%">
                &lt;{{ printModel.form.testMethodTotalColiforms }} MPN/g
              </td> -->
              <td style="width: 35%" v-if="
            !printModel.form.testMethodTotalColiforms || 
            printModel.form.testMethodTotalColiforms.toLowerCase()=='negative' || 
            printModel.form.testMethodTotalColiforms.toLowerCase()=='阴性'">
              {{ printModel.form.testMethodTotalColiforms }}
            </td>
            <td style="width: 35%" v-else>
              &lt;{{ printModel.form.testMethodTotalColiforms }} MPN/g
            </td>
              <td style="width: 35%">{{ printModel.form.analysisMethodTotalColiform }}</td>
            </tr>
            <tr v-show="printModel.form.liquidExtCustomerKey5">
              <td style="width: 35%">
                <b>{{ printModel.form.liquidExtCustomerKey5 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue5 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue5TM }}
              </td>
            </tr>
            <tr v-show="printModel.form.liquidExtCustomerKey6">
              <td style="width: 35%">
                <b>{{ printModel.form.liquidExtCustomerKey6 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue6 }}
              </td>
              <td style="width: 35%">
                {{ printModel.form.liquidExtCustomerValue6TM }}
              </td>
            </tr>
          </tbody>
        </table>

        <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>其他</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-show="printModel.form.shelflife">
              <td style="width: 30%"><b>保质期</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.form.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.form.storage">
              <td style="width: 30%"><b>储存条件</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.form.storage }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="clear: both"></div>
        <div style="height: 20px"></div>
        <div style="font-size: 18px">
          <b><u>注释</u></b>
        </div>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span><i>* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。</i></span>
          </div>
          <div style="height: 10px"></div>
          <div v-if="printModel.form.isShowNotes2">
            <span><i>* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。</i></span>
          </div>
          <div style="height: 10px" v-if="printModel.form.isShowNotes2"></div>
          <div>
            <span><i>* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。</i></span>
          </div>
          <div style="height: 10px"></div>
          <div>
            <span><i>* 如果产品中含水量低于50%，则产品的pH 值必须在1%产品的水溶液中测定。</i></span>
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <div style="height: 10px"></div>
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>  
  </div>
  
</template>
<script>
import FooterSigner from "./Cn-FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
      imageUrl:"",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        // this.printModel.ingredientsArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.partUsedArr.length,
        this.printModel.percentageArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.form.tableInput1 || this.printModel.form.tableInput2 || this.printModel.form.tableInput3 || this.printModel.form.tableInput4 || this.printModel.form.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {
    isSingle() {
      var count = 0;
      var name = "";
      var arr = [];
      if (this.printModel.inclNameArr.length >= 1) {
        name = "INCI名称";
        count = count + 1;
        arr = this.printModel.inclNameArr;
      }
      if (this.printModel.commonNameArr.length >= 1) {
        name = "通用名/其他名称";
        count = count + 1;
        arr = this.printModel.commonNameArr;
      }
      if (this.printModel.botanicalNameArr.length >= 1) {
        name = "植物名称";
        count = count + 1;
        arr = this.printModel.botanicalNameArr;
      }
      if (this.printModel.partUsedArr.length >= 1) {
        name = "使用部位";
        count = count + 1;
        arr = this.printModel.partUsedArr;
      }
      if (this.printModel.percentageArr.length >= 1) {
          name = "百分比";
          count = count + 1;
          arr = this.printModel.percentageArr;
        }
      let res = { count, name, arr };
      console.log("this.printModel");
      console.log(this.printModel);
      return res;
    },
  },mounted(){ 
    // if(this.printModel.form.confidential==true){
    //   // var pageDiv=document.getElementById("pageDiv");
    //   // pageDiv.style.backgroundImage="url("+this.printModel.imageUrl+")";
    // }  
  }
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>